<template>
  <div>
    <!-- Your header, navigation, etc. -->
    <router-view></router-view> <!-- Routed components will be displayed here -->
    <!-- Your footer, etc. -->
  </div>
</template>

<script>
import {computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'

export default {
  name: 'App',
  setup() {
    const siteData = reactive({
      title: `Tourette Agency - Makin Ideas Happen`,
      description: `Somos una agencia de publicidad independiente nacida el 2018. Sin embargo, el cambio constante del mercado nos hace renacer cada año, reinventando nuestra estructura, métodos, procesos y creencias.`,
    })
    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
        ],
     
    })
  },
  data () {
    return {
      showNavbar: true,
      lastScrollPosition: 0
    }
  },
  mounted () {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll () {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      if (currentScrollPosition < 0) {
        return
      }
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
        return
      }
      this.showNavbar = currentScrollPosition < this.lastScrollPosition
      this.lastScrollPosition = currentScrollPosition
    }
  }
};

</script>


<style>
* {
  box-sizing: border-box;
}
body {
  padding: 0;
  margin: 0;
}
.app {
  width: 100vw;
  background: hsl(200, 50%, 90%);
}

.navbar.navbar--hidden {
  box-shadow: none;
  transform: translate3d(0, -170%, 0);
  transition: transform 0.6s ease-in-out; 
}
</style>


