import { createApp } from 'vue'
import './style.css'
import App from './App.vue'
import Vue3Marquee from 'vue3-marquee'
import PrimeVue from 'primevue/config'
import VueWriter from "vue-writer";
import { createHead } from '@vueuse/head'
import router from './router/routes';
const head = createHead()

// createApp(App).use(head).use(router).mount('#app')
createApp(App).use(Vue3Marquee).use(router).use(head).use(PrimeVue).use(VueWriter).mount('#app')

